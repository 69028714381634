import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import React, { Component } from "react";

import Formulario from "./formulario";
import MediaCard from "./tratamientos_detalle.js";

class MangeTextoInicial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titulo: "",
      descripcion: "",
      mainTitulo: [],
      token: "",
      formstate: null,
      idFamiliaEditar: 0,
      file: "",
      currentSelectedOne: 0,
      statusLoad: false,
      serverUrl: "",
    };
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.editarFamilia = this.editarFamilia.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submitForm(e) {
    let that = this;
    e.preventDefault();

    //this.setState({ formstate: e });
    if (this.state.file != null) {
      that.setState(
        {
          statusLoad: true,
        },
        () => {
          this.sendData();
          // this.state.statusLoad = true;
          //WHATEVER YOU WANT TO BE EXECUTED AFTER SETTING STATE..
          //MAY BE YOUR CONSOLE LOG STATEMENT TOO
        }
      );

      // e.target.reset();
    } else {
      console.log("it is empty");
    }
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  uploadFileData(cedula) {
    let data = new FormData();
    data.append("file", this.state.file);

    fetch(this.state.serverUrl + "/v1/uploadhomeimages/" + cedula, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.state.token,
      },
      body: data,
    })
      .then((response) => {
        //this.setState({ imagename: cedula + ".jpg" });
        this.getTextoInicial();

        // this.setState({ statusLoad: false });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  handleChange(event) {
    this.setState({ titulo: event.target.value });
  }

  sendData() {
    const data = {
      titulo: this.state.titulo,
      descripcion: this.state.descripcion,
    };

    fetch(this.state.serverUrl + "/v1/updatetextoinicial", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      this.getTextoInicial();
    });
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.setState({
      token: tokenStorage,
      serverUrl: sessionStorage.getItem("serverUrl"),
    });
    this.getTextoInicial();
  }

  getTextoInicial() {
    fetch(this.state.serverUrl + "/v1/gettextoinicial", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ mainTitulo: data, statusLoad: false });
      });
  }

  handleClicks(s, id) {
    console.log("dada" + s);
  }

  render() {
    var styles1 = {
      "text-align": "justify",
    };
    let newText = "";
    if (this.state.mainTitulo.descripcion != null) {
      newText = this.state.mainTitulo.descripcion
        .split("\n")
        .map((items, i) => {
          return (
            <p key={i} style={{ textAlign: "justify", fontSize: "15px" }}>
              {items}
            </p>
          );
        });
    }

    return (
      <div>
        <Formulario
          titulo={this.titulo}
          onsubmit={this.submitForm}
          onChange={this.onChange}
          onFileChange={this.onFileChange}
        ></Formulario>
        <br /> <br />
        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> <br />
        <MediaCard
          titulo={this.state.mainTitulo.titulo}
          descripcion={newText}
        ></MediaCard>
      </div>
    );
  }
}

export default MangeTextoInicial;
