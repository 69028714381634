import jwt from 'jsonwebtoken';

const currentUser = sessionStorage.getItem("token");

export const authenticationService = {
    
    
    get currentUserValue () { 
    
        if(currentUser==null){

            //  
            return false;

        }else{
            
            return true;
        } 
    
    },

    getRol(){

        return parseInt(sessionStorage.getItem("rol"));
  
    },

    loGout(){

        sessionStorage.clear();
        window.location.href = "/";
    },

    checkTokenSessionStatus(){
        //USEFUL FUNCTION TO CHECK IF JWT HAS EXPERIED OR NOT
        
        if(jwt.decode(sessionStorage.getItem("token")).exp < Date.now() / 1000){
            sessionStorage.clear();
            window.location.href = "/";
        }
    }

};
