import React, { Component } from "react";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";

import { authenticationService } from "./checkLoginStatus";

import Login from "./Login/index";
import AdminDashboard from "./views/admin/AdminDashboard";
import SuperadminDashboard from "./views/superadmin/SuperadminDashboard";

class App extends Component {
  /******ROLES 

  3: SUPERADMIN
  2: ADMIN
  1: ENDUSER


  ********** */

  componentDidMount() {
    //sessionStorage.setItem("serverUrl", "http://localhost:7079");
    sessionStorage.setItem("serverUrl", "https://admin.aucesonline.com");
  }

  render() {
    if (!authenticationService.currentUserValue) {
      return (
        <Router>
          <Switch>
            <Route exact={true} path="/login" component={Login} />

            <Redirect to="/login" />
          </Switch>
        </Router>
      );
    } else {
      //*******************VALIDATING ROLES

      if (authenticationService.getRol() == 3) {
        return (
          <Router>
            <SuperadminDashboard></SuperadminDashboard>
          </Router>
        );
      }

      if (authenticationService.getRol() == 2) {
        return (
          <Router>
            <AdminDashboard></AdminDashboard>
          </Router>
        );
      }

      //*******************VALIDATING ROLES
    }
  }
}

export default App;
