import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import React, { Component } from "react";

import MediaCard from "./familia_tratamientos_detalle";
import Formulario from "./formulario";

class FamiliaTratamientos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tituloFamiliaTratamiento: "",
      descripcionFamiliatratamiento: "",
      familiaTratamientos: [],
      token: "",
      formstate: null,
      idFamiliaEditar: 0,
      file: "",
      serverUrl: "",
    };
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editarFamilia = this.editarFamilia.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submitForm(e) {
    let that = this;
    e.preventDefault();

    this.setState({ formstate: e });
    if (this.state.tituloFamiliaTratamiento.length > 0) {
      that.setState(
        {
          statusLoad: true,
        },
        () => {
          this.sendData();
          // this.state.statusLoad = true;
          //WHATEVER YOU WANT TO BE EXECUTED AFTER SETTING STATE..
          //MAY BE YOUR CONSOLE LOG STATEMENT TOO
        }
      );

      // e.target.reset();
    } else {
    }
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  getIdFamiliaTratamientoBeforeUploadingImage(titulo) {
    fetch(this.state.serverUrl + "/v1/getidfamiliastratamientos", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: titulo,
    })
      .then((response) => response.json())
      .then((data) => {
        this.uploadFileData(data);
      });
  }

  uploadFileData(cedula) {
    let data = new FormData();
    data.append("file", this.state.file);

    fetch(
      this.state.serverUrl + "/v1/uploadimagefamiliatratamiento/" + cedula,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.state.token,
        },
        body: data,
      }
    )
      .then((response) => {
        //this.setState({ imagename: cedula + ".jpg" });
        this.getFamiliasTratamientos();
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  handleChange(event) {
    this.setState({ tituloFamiliaTratamiento: event.target.value });
  }

  sendData() {
    const data = {
      tituloCategoria: this.state.tituloFamiliaTratamiento,
      descripcion: this.state.descripcionFamiliatratamiento,
    };

    fetch(this.state.serverUrl + "/v1/createfamiliatratamiento", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.status === 201) {
        this.getIdFamiliaTratamientoBeforeUploadingImage(
          this.state.tituloFamiliaTratamiento
        );
        document.getElementById("formulario").reset();
      }
    });
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.setState({
      token: tokenStorage,
      serverUrl: sessionStorage.getItem("serverUrl"),
    });
    this.getFamiliasTratamientos();
  }

  getFamiliasTratamientos() {
    fetch(this.state.serverUrl + "/v1/getallfamiliastratamientos", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        this.setState({ familiaTratamientos: data, statusLoad: false });
      });
  }

  editarFamilia() {
    console.log("familia selected..");
  }
  handleClicks(s, id) {
    console.log("dada" + s);
  }

  eliminarFamiliaTratamiento(s, id) {
    fetch(this.state.serverUrl + "/v1/deletefamiliatratamiento/" + s, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify("data"),
    }).then((response) => {
      this.getFamiliasTratamientos();
    });
  }

  verTratatmientosDetalle(s, id) {}


  updateIndice(s, id){

    

    if(this.state.indice!=""){

   
      fetch(this.state.serverUrl + "/v1/changefamiliatratamientorder/"+s+"/"+this.state.indice, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        body: JSON.stringify(""),
      }).then((response) => {
        if (response.status === 201) {
          
          window.location.reload();
        }
      });


      
    }


  }

  cambiar(name ,e){
   

    var change = {};
    change[name] = e.target.value;
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({indice:e.target.value});
    }
   

  }



  render() {
    return (
      <div>
        <Formulario
          onsubmit={this.submitForm}
          tituloFamiliaTratamiento={this.tituloFamiliaTratamiento}
          onChange={this.onChange}
          onFileChange={this.onFileChange}
        ></Formulario>
        <br /> <br />
        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> <br />
        {this.state.familiaTratamientos.map((item) => {
          return (
            <div key={item.id}>
              <MediaCard
               onChange={this.cambiar.bind(this, item.indice)}
                titulo={item.tituloCategoria}
                descripcion={item.descripcion}
                urlImage={item.urlImage}
                totaltratamientos={item.listadoTratamientos.length}
                editar={this.handleClicks.bind(this, item.id)}
                eliminar={this.eliminarFamiliaTratamiento.bind(this, item.id)}
                vertratamientosdetalle={this.verTratatmientosDetalle.bind(
                  this,
                  item.id
                )}
                indexc = {item.indice}
               
                actualizarIndice={this.updateIndice.bind(this, item.id)}
              ></MediaCard>
              <br /> <br />
              <Divider />
              <br /> <br />
            </div>
          );
        })}
      </div>
    );
  }
}

export default FamiliaTratamientos;
