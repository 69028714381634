import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import React from "react";

const useStyles = makeStyles({});

export default function MediaCard(props) {
  const classes = useStyles();
  var styles1 = {
    width: "100%",
    height: "auto",
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <Card className={classes.root}>
            <CardActionArea>
              <img src={props.urlImage} style={styles1} />

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {props.titulo}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.descripcion}
                </Typography>
              </CardContent>

              <CardContent>
                <InputBase
                  defaultValue={
                    "TOTAL TRATAMIENTOS: " + props.totaltratamientos
                  }
                />
                <br />
                <InputBase
                  defaultValue={
                    "INDICE ACTUAL: " +props.indexc
                  }
                /><br></br>
      

<TextField
                      id={"data-"+props.indexc}
                      
                      required
                      
                   
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    
                      onChange={props.onChange}
                  
                    />
                    <br></br>
              </CardContent>
            </CardActionArea>

            <CardActions>
              <Button size="small" color="primary" onClick={props.eliminar}>
                ELIMINAR
              </Button>
              <Button size="small" color="primary" onClick={props.actualizarIndice}>
                              Actualizar Indice
                            </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
