import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import React, { Component } from "react";

import Formulario from "./formulario";

class EstadoPedidos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fechaDesde: moment().format("YYYY-MM-DD") + "T21:11:54",
      fechaHasta: moment().format("YYYY-MM-DD") + "T21:11:54",
      fechaFinalDesde: "",
      fechaFinalHasta: "",
      initialDate: moment().format("YYYY-MM-DD"),
      totalPages: 0,
      totalPedidos: [],
      pedidosDetalle: [],
      serverUrl: "",
      token: "",
      radioValue: "",
      alertstatus: false,
      mensaje: "",
    };
    this.onChange = this.onChange.bind(this);
    this.clickRrow = this.clickRrow.bind(this);
    this.updateEstado = this.updateEstado.bind(this);
    this.handleDateChangeDesde = this.handleDateChangeDesde.bind(this);
    this.handleDateChangeHasta = this.handleDateChangeHasta.bind(this);
    this.handleGetPedidos = this.handleGetPedidos.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDateChangeDesde(e) {
    this.setState({
      alertstatus: false,
    });
    var date = new Date(e);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    let fecha = moment(date).format("YYYY-MM-DD") + "T21:11:54";

    this.setState({
      fechaDesde: fecha,
      fechaFinalDesde: moment(fecha).format("YYYY-MM-DD"),
    });
    console.log("f. desde " + moment(fecha).format("YYYY-MM-DD"));
  }

  handleDateChangeHasta(e) {
    this.setState({
      alertstatus: false,
    });
    var date = new Date(e);

    let fecha = moment(date).format("YYYY-MM-DD") + "T21:11:54";

    this.setState({
      fechaHasta: fecha,
      fechaFinalHasta: moment(fecha).format("YYYY-MM-DD"),
    });

    console.log("f. hasta " + moment(fecha).format("YYYY-MM-DD"));
  }

  componentDidMount() {
    var date = new Date();
    let fecha = moment(date).format("YYYY-MM-DD") + "T21:11:54";

    this.setState({
      fechaFinalHasta: moment(fecha).format("YYYY-MM-DD"),
      fechaFinalDesde: moment(fecha).format("YYYY-MM-DD"),
    });

    /**/
  }

  clickRrow(e) {
    fetch(this.state.serverUrl + "/v1/getpedidosdetalle/" + e, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ pedidosDetalle: data });
      });
  }

  updateEstado(e, i) {
    e.preventDefault();

    fetch(this.state.serverUrl + "/v1/updateordenpedido", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: i,
    }).then((response) => {
      if (response.status === 201) {
        //console.log("process it");
        this.setState({ pedidosDetalle: [] });
        this.gettingPedidos();
      }
    });
  }

  handleRadio(e) {
    this.setState({
      alertstatus: false,
    });
    console.log("data.." + e.target.value);
    this.setState({ radioValue: e.target.value });
  }

  handleGetPedidos() {
    this.setState({
      alertstatus: false,
      pedidosDetalle: [],
      totalPedidos: [],
    });

    console.log("fecha desde " + this.state.fechaFinalDesde);
    console.log("fecha hasta " + this.state.fechaFinalHasta);

    var startTime = new Date(this.state.fechaFinalDesde);
    var endTime = new Date(this.state.fechaFinalHasta);
    if (endTime < startTime) {
      //start is less than End

      this.setState({
        mensaje: "La fecha desde no puede ser mayor a la fecha hasta",
        alertstatus: true,
      });
    } else {
      this.gettingPedidos();
    }
  }

  gettingPedidos() {
    if (this.state.radioValue !== "") {
      this.state.serverUrl = sessionStorage.getItem("serverUrl");
      let tokenStorage = sessionStorage.getItem("token");
      this.setState({
        token: tokenStorage,
        serverUrl: sessionStorage.getItem("serverUrl"),
      });

      fetch(
        this.state.serverUrl +
          "/v1/getpedidospaginados/0/" +
          this.state.fechaFinalDesde +
          "/" +
          this.state.fechaFinalHasta +
          "/" +
          this.state.radioValue,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.state.token,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          this.setState({ totalPedidos: data });
        });
    } else {
      console.log("seleccione un estado");
      this.setState({
        mensaje: "Seleccione un estado para continuar",
        alertstatus: true,
      });
    }
  }

  render() {
    return (
      <div>
        <Formulario
          alertstatus={this.state.alertstatus}
          mensaje={this.state.mensaje}
          fechaHasta={this.state.fechaHasta}
          fechaDesde={this.state.fechaDesde}
          handleDateChangeDesde={this.handleDateChangeDesde}
          handleDateChangeHasta={this.handleDateChangeHasta}
          totalPedidos={this.state.totalPedidos.infoData}
          clickingRrow={this.clickRrow}
          pedidosDetalle={this.state.pedidosDetalle}
          updateEstadoing={this.updateEstado}
          radioValue={this.radioValue}
          handleRadio={this.handleRadio}
          handleGetPedidos={this.handleGetPedidos}
        ></Formulario>
        <br /> <br />
        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> <br />
        <div></div>
      </div>
    );
  }
}

export default EstadoPedidos;
