import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";

import React from "react";

const useStyles = makeStyles({});

export default function MediaCard(props) {
  const classes = useStyles();
  var styles1 = {
    width: "100%",
    height: "auto",
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <Card className={classes.root}>
            <CardActionArea>
              <img src={props.urlImage} style={styles1} />

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {props.titulo}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.descripcion}
                </Typography>
              </CardContent>

              <CardContent>
                <InputBase
                  defaultValue={"TOTAL VECES VISTO: " + props.totaltratamientos}
                />
                <br />
              </CardContent>
            </CardActionArea>

            <CardActions>
              <Button size="small" color="primary" onClick={props.eliminar}>
                ELIMINAR
              </Button>
              <Button size="small" color="primary" onClick={props.editar}>
                EDITAR
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
