import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Divider from "@material-ui/core/Divider";

import clsx from "clsx";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Formulario(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Grid container>
        <Grid item xs={12}>
          <center>
            <h2>CREAR FAMILIA DE TRATAMIENTOS</h2>
          </center>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
                <form validate="true" onSubmit={props.onsubmit} id="formulario">
                  <FormControl>
                    <TextField
                      id="outlined-full-width"
                      label="Titulo"
                      required
                      style={{ margin: 8 }}
                      placeholder="Titulo Familia Tratamiento"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={props.tituloFamiliaTratamiento}
                      onChange={props.onChange}
                      name="tituloFamiliaTratamiento"
                    />
                    <br />
                    <Divider />
                    <br />
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      onChange={props.onFileChange}
                    />
                    <br />
                    <TextField
                      id="outlined-multiline-static"
                      label="Descripcion"
                      multiline
                      required
                      rows={4}
                      variant="outlined"
                      value={props.descripcionFamiliatratamiento}
                      onChange={props.onChange}
                      name="descripcionFamiliatratamiento"
                    />
                    <br />
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      GUARDAR
                    </Button>
                  </FormControl>
                </form>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
