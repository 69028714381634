import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import ReactDOM from "react-dom";

import { authenticationService } from "../checkLoginStatus";

import SignIn from "./components/Login";

class Login extends React.Component {
  constructor(props) {
    super(props);
    /*
        if(authenticationService.currentUserValue){
            if(authenticationService.getRol()==3){
                window.location.href = "/superadmin/";
            }
            if(authenticationService.getRol()==2){
                window.location.href = "/admin/";
            }
        }*/

    this.state = {
      username: "",
      password: "",
    };
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  onChange(e) {
    console.log("printing from here..");
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submitForm(e) {
    e.preventDefault();
    const user = {
      usernameOrEmail: this.state.username,
      password: this.state.password,
    };

    fetch(sessionStorage.getItem("serverUrl") + "/api/auth/signin", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let role = "";

        data.roles.map((i) => {
          role = parseInt(i.role_id);
        });

        if (role === 3) {
          //superadmin dasbhboard

          sessionStorage.setItem("token", data.accessToken);
          //sessionStorage.setItem("name", data.infoEmpresa.nombreUsuario);
          sessionStorage.setItem("rol", role);

          this.setState({ isAuthenticated: true });

          window.location.href = "/superadmin/";
        }
        if (role === 2) {
          //admin dashboard

          sessionStorage.setItem("token", data.accessToken);
          //sessionStorage.setItem("name", data.infoEmpresa.nombreUsuario);
          sessionStorage.setItem("rol", role);

          this.setState({ isAuthenticated: true });
          window.location.href = "/admin/";
        }
        if (role === 1) {
          //admin enduser
        }
      })
      .catch((err) => console.error(err));
  }

  render() {
    /*if(this.state.isAuthenticated){


            return <Redirect to="/superadmin"/>

        }*/

    return (
      <div>
        {/*
                <h1>login</h1>
                <form onSubmit={this.submitForm}>

                    <input type="text" placeholder="username" name="username" value={this.state.username} onChange={this.onChange}></input>
                    <br/>
                    <input type="password" placeholder="password" name="password" value={this.state.password} onChange={this.onChange}></input>
                    <br/>
                    <input type="submit" ></input>

                </form>
                    */}

        <SignIn
          onChange={this.onChange}
          username={this.username}
          password={this.password}
          onsubmit={this.submitForm}
        />
      </div>
    );
  }
}

export default Login;
