import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import React, { Component } from "react";

import Formulario from "./formulario";
import MediaCard from "./tratamientos_detalle.js";

class MangeHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titulo: "",
      descripcionFamiliatratamiento: "",
      arrayImages: [],

      token: "",
      formstate: null,
      idFamiliaEditar: 0,
      file: "",
      currentSelectedOne: 0,
      statusLoad: false,
      serverUrl: "",
    };
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.editarFamilia = this.editarFamilia.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submitForm(e) {
    let that = this;
    e.preventDefault();

    //this.setState({ formstate: e });
    if (this.state.file != null) {
      that.setState(
        {
          statusLoad: true,
        },
        () => {
          this.sendData();
          // this.state.statusLoad = true;
          //WHATEVER YOU WANT TO BE EXECUTED AFTER SETTING STATE..
          //MAY BE YOUR CONSOLE LOG STATEMENT TOO
        }
      );

      // e.target.reset();
    } else {
      console.log("it is empty");
    }
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  uploadFileData(cedula) {
    let data = new FormData();
    data.append("file", this.state.file);

    fetch(this.state.serverUrl + "/v1/uploadhomeimages/" + cedula, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.state.token,
      },
      body: data,
    })
      .then((response) => {
        //this.setState({ imagename: cedula + ".jpg" });
        this.getAllImages();

        // this.setState({ statusLoad: false });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  handleChange(event) {
    this.setState({ titulo: event.target.value });
  }

  sendData() {
    fetch(this.state.serverUrl + "/v1/createhomeimage", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("THE ID IS " + data);
        if (data === 0) {
          this.setState({ statusLoad: false });
        } else {
          this.uploadFileData(data);
        }
        //
      });
  }

  componentWillMount() {
    let tokenStorage = sessionStorage.getItem("token");

    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    this.setState({
      token: tokenStorage,
    });
    this.getAllImages();
  }

  getAllImages() {
    console.log("URL " + this.state.serverUrl);
    fetch(this.state.serverUrl + "/v1/getallhomeimages", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ arrayImages: data, statusLoad: false });
      });
  }

  handleClicks(s, id) {
    console.log("dada" + s);
  }

  eliminarImage(s, id) {
    fetch(this.state.serverUrl + "/v1/deletehomeimage/" + s, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify("data"),
    }).then((response) => {
      this.getAllImages();
    });
  }

  render() {
    let options = this.state.arrayImages.map((data, index) => (
      <MenuItem key={data.id} value={data.id}>
        {data.tituloCategoria}
      </MenuItem>
    ));

    var styles1 = {
      "text-align": "justify",
    };

    return (
      <div>
        <Formulario
          onsubmit={this.submitForm}
          onFileChange={this.onFileChange}
        ></Formulario>
        <br /> <br />
        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> <br />
        {this.state.arrayImages.map((item) => {
          return (
            <div key={item.id}>
              <MediaCard
                urlImage={item.imageUrl}
                eliminar={this.eliminarImage.bind(this, item.id)}
              ></MediaCard>
              <br /> <br />
              <Divider />
              <br /> <br />
            </div>
          );
        })}
      </div>
    );
  }
}

export default MangeHome;
