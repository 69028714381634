import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import React, { Component } from "react";

import Formulario from "./formulario";
import MediaCard from "./tratamientos_detalle.js";

class arrayTratamientos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tituloTratamiento: "",
      descripcionTratamiento: "",
      arrayTratamientos: [],
      tratamientosDetalle: [],
      token: "",
      formstate: null,
      idFamiliaEditar: 0,
      file: "",
      currentSelectedOne: 0,
      statusLoad: false,
      serverUrl: "",
      precioProducto: 0,
    };
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editarFamilia = this.editarFamilia.bind(this);
    this.trataMientoSelected = this.trataMientoSelected.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submitForm(e) {
    let that = this;
    e.preventDefault();
    console.log("from here ..");
    //this.setState({ formstate: e });
    if (this.state.tituloTratamiento.length > 0) {
      that.setState(
        {
          statusLoad: true,
        },
        () => {
          this.sendData();
          // this.state.statusLoad = true;
          //WHATEVER YOU WANT TO BE EXECUTED AFTER SETTING STATE..
          //MAY BE YOUR CONSOLE LOG STATEMENT TOO
        }
      );

      // e.target.reset();
    } else {
    }
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  getIdTratamientoBeforeUploadingImage(titulo) {
    fetch(this.state.serverUrl + "/v1/getidtratamientos", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: titulo,
    })
      .then((response) => response.json())
      .then((data) => {
        this.uploadFileData(data);
      });
  }

  uploadFileData(cedula) {
    let data = new FormData();
    data.append("file", this.state.file);

    fetch(
      this.state.serverUrl + "/v1/uploadimagetratamientodetalle/" + cedula,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.state.token,
        },
        body: data,
      }
    )
      .then((response) => {
        //this.setState({ imagename: cedula + ".jpg" });
        this.getAllTratamientosDetalle();

        // this.setState({ statusLoad: false });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  handleChange(event) {
    this.setState({ tituloTratamiento: event.target.value });
  }

  sendData() {
    console.log("variable status  " + this.state.statusLoad);
    const data = {
      fkIdFamiliaTratamientos: this.state.currentSelectedOne,
      tituloTratamiento: this.state.tituloTratamiento,
      descripcion: this.state.descripcionTratamiento,
      precio: this.state.precioProducto,
    };

    fetch(this.state.serverUrl + "/v1/createtratamientodetalle", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.status === 201) {
        this.getIdTratamientoBeforeUploadingImage(this.state.tituloTratamiento);
        document.getElementById("formulario").reset();
      }
    });
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.setState({
      token: tokenStorage,
      serverUrl: sessionStorage.getItem("serverUrl"),
    });
    this.getFamiliasTratamientos();
  }

  getFamiliasTratamientos() {
    fetch(this.state.serverUrl + "/v1/getallfamiliastratamientos", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        this.setState({ arrayTratamientos: data });
      });
  }

  editarFamilia() {
    console.log("familia selected..");
  }
  handleClicks(s, id) {
    console.log("dada" + s);
  }

  eliminarTratamiento(s, id) {
    fetch(this.state.serverUrl + "/v1/deletetratamientodetalle/" + s, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify("data"),
    }).then((response) => {
      this.getAllTratamientosDetalle();
    });
  }

  trataMientoSelected(event) {
    console.log("THE ID TREATMENT IS " + event.target.value);
    //   this.setState({ currentSelectedOne: event.target.value });
    this.state.currentSelectedOne = event.target.value;
    console.log("afetr set state = " + this.state.currentSelectedOne);
    /*
    let counter = 0;
    this.state.arrayTratamientos.map((data) => {
      if (counter === parseInt(event.target.value)) {
        this.setState({ currentSelectedOne: data.id });
      }
      counter++;
    });*/
    this.getAllTratamientosDetalle();
  }

  getAllTratamientosDetalle() {
    console.log(
      "CALLING ENDPOINT ..." +
        this.state.serverUrl +
        "/v1/getalltratamientos/" +
        this.state.currentSelectedOne
    );
    fetch(
      this.state.serverUrl +
        "/v1/getalltratamientos/" +
        this.state.currentSelectedOne,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ tratamientosDetalle: data, statusLoad: false });
      });
  }

  updatePrecio(s, id){

    

    if(this.state.precioProducto!=""){

   
      fetch(this.state.serverUrl + "/v1/updateprecio/"+s+"/"+this.state.precioProducto, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        body: JSON.stringify(""),
      }).then((response) => {
        if (response.status === 201) {
         
          window.location.reload();
        }
      });


      
    }


  }

  updateIndice(s, id){

    

    if(this.state.indice!=""){

   
      fetch(this.state.serverUrl + "/v1/changetratamientosdetalleorder/"+s+"/"+this.state.indice, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        body: JSON.stringify(""),
      }).then((response) => {
        if (response.status === 201) {
          
          window.location.reload();
        }
      });


      
    }


  }

  cambiar(name ,e){
   

    var change = {};
    change[name] = e.target.value;
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({indice:e.target.value});
    }
   

  }

  cambiarPrecio(name ,e){
   

    var change = {};
    change[name] = e.target.value;
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({precioProducto:e.target.value});
    }
   

  }



  render() {
    let options = this.state.arrayTratamientos.map((data, index) => (
      <MenuItem key={data.id} value={data.id}>
        {data.tituloCategoria}
      </MenuItem>
    ));

    var styles1 = {
      "text-align": "justify",
    };

    return (
      <div>
        <Formulario
          onsubmit={this.submitForm}
          tituloTratamiento={this.tituloTratamiento}
          onChange={this.onChange}
          onFileChange={this.onFileChange}
          selectoptions={options}
          selectedChange={this.trataMientoSelected}
          currentValue={this.currentSelectedOne}
          status={this.statusLoad}
          precioProducto={this.precioProducto}
        ></Formulario>
        <br /> <br />
        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> <br />
        {this.state.tratamientosDetalle.map((item) => {
          let newText = item.descripcion.split("\n").map((items, i) => {
            return (
              <p key={i} style={{ textAlign: "justify", fontSize: "15px" }}>
                {items}
              </p>
            );
          });
          return (
            <div key={item.id}>
              <MediaCard
              onChange={this.cambiar.bind(this, item.indice)}
              onChange2={this.cambiarPrecio.bind(this, item.indice)}
                titulo={item.tituloTratamiento}
                descripcion={newText}
                urlImage={item.urlImage}
                vecesvisto={item.vecesVisto}
                editar={this.handleClicks.bind(this, item.id)}
                eliminar={this.eliminarTratamiento.bind(this, item.id)}
                precio={item.precio}
                indexc = {item.indice}
               
               actualizarIndice={this.updateIndice.bind(this, item.id)}
               actualizarPrecio={this.updatePrecio.bind(this, item.id)}
              ></MediaCard>
              <br /> <br />
              <Divider />
              <br /> <br />
            </div>
          );
        })}
      </div>
    );
  }
}

export default arrayTratamientos;
