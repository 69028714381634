import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import FormLabel from "@material-ui/core/FormLabel";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import deLocale from "date-fns/locale/es";
import green from "@material-ui/core/colors/green";

import clsx from "clsx";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    color: green[600],
    "&$checked": {
      color: green[500],
    },
  },
  checked: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Formulario(props) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
      formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  return (
    <Container>
      <Grid container>
        <Grid>
          <center>
            <h2>ESTADO DE PEDIDOS</h2>
          </center>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
                <form validate="true" onSubmit={props.onsubmit} id="formulario">
                  <FormControl>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha desde"
                          value={props.fechaDesde}
                          onChange={props.handleDateChangeDesde}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                        <br></br>

                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha hasta"
                          value={props.fechaHasta}
                          onChange={props.handleDateChangeHasta}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <br></br>

                    {props.totalPedidos !== undefined ? (
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Accion</TableCell>
                              <TableCell>Usuario</TableCell>
                              <TableCell align="right">Tel</TableCell>
                              <TableCell align="right">Direccion</TableCell>
                              <TableCell align="right">Barrio</TableCell>
                              <TableCell align="right">Ciudad</TableCell>

                              <TableCell align="right">Medio de pago</TableCell>

                              <TableCell align="right">TotalPedido</TableCell>
                              <TableCell align="right">Fecha</TableCell>

                              <TableCell align="right">Procesado</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props.totalPedidos.map((row) => (
                              <TableRow
                                key={row.id}
                                onClick={() => props.clickingRrow(row.id)}
                              >
                                <TableCell align="right">
                                  <button
                                    onClick={(e) =>
                                      props.updateEstadoing(e, row.id)
                                    }
                                  >
                                    Enviado
                                  </button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.nombreUsuario}
                                </TableCell>
                                <TableCell align="right">
                                  {row.telContacto}
                                </TableCell>
                                <TableCell align="right">
                                  {row.direccion}
                                </TableCell>
                                <TableCell align="right">
                                  {row.barrio}
                                </TableCell>
                                <TableCell align="right">
                                  {row.ciudad}
                                </TableCell>

                                <TableCell align="right">
                                  {row.mediodePago}
                                </TableCell>

                                <TableCell align="right">
                                  ${formatNumber(row.totalDomicilio)}
                                </TableCell>
                                <TableCell align="right">{row.fecha}</TableCell>

                                <TableCell align="right">
                                  {row.procesado}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      "void"
                    )}

                    <center>
                      <br></br>
                      <FormControl>
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={props.radioValue}
                          onChange={props.handleRadio}
                        >
                          <FormControlLabel
                            value="procesados"
                            control={<Radio />}
                            label="Procesados"
                          />

                          <FormControlLabel
                            value="noprocesados"
                            control={<Radio />}
                            label="No Procesado"
                          />
                        </RadioGroup>
                      </FormControl>
                      <br></br>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={props.handleGetPedidos}
                      >
                        BUSCAR PEDIDOS
                      </Button>
                      <br />
                      <br />
                      {props.alertstatus ? (
                        <Alert severity="error">{props.mensaje}</Alert>
                      ) : (
                        ""
                      )}
                    </center>
                  </FormControl>
                </form>
              </div>
            </div>
          </Paper>

          <center>
            <h2>DETALLES DE PEDIDO</h2>
          </center>

          <Paper className={classes.paper}>
            <div className={classes.root}>
              <div>
                <form validate="true" onSubmit={props.onsubmit} id="formulario">
                  <FormControl>
                    {props.pedidosDetalle !== undefined ? (
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>PRODUCTO</TableCell>
                              <TableCell align="right">CANTIDAD</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props.pedidosDetalle.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                  {row.producto}
                                </TableCell>
                                <TableCell align="right">
                                  {row.cantidad}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      "void"
                    )}
                  </FormControl>
                </form>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
