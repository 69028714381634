import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

import React, { Component } from "react";

import Formulario from "./formulario";
import MediaCard from "./temasdeSaludDetalle";

class TemasDeSalud extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tituloTemaSalud: "",
      descripcionTemaSalud: "",
      arrayTemasSalud: [],
      token: "",
      formstate: null,
      idFamiliaEditar: 0,
      file: "",
      serverUrl: "",
      totalPages: 0,
    };
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editarFamilia = this.editarFamilia.bind(this);
    this.getclickedPagination = this.getclickedPagination.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submitForm(e) {
    let that = this;
    e.preventDefault();

    this.setState({ formstate: e });
    if (this.state.tituloTemaSalud.length > 0) {
      that.setState(
        {
          statusLoad: true,
        },
        () => {
          this.sendData();
          // this.state.statusLoad = true;
          //WHATEVER YOU WANT TO BE EXECUTED AFTER SETTING STATE..
          //MAY BE YOUR CONSOLE LOG STATEMENT TOO
        }
      );

      // e.target.reset();
    } else {
    }
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  getIdTemaSaludBeforeUploadingImage(titulo) {
    fetch(this.state.serverUrl + "/v1/getidtemadesalud", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: titulo,
    })
      .then((response) => response.json())
      .then((data) => {
        this.uploadFileData(data);
      });
  }

  uploadFileData(cedula) {
    let data = new FormData();
    data.append("file", this.state.file);

    fetch(this.state.serverUrl + "/v1/uploadhomeimagestemasdesalud/" + cedula, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.state.token,
      },
      body: data,
    })
      .then((response) => {
        //this.setState({ imagename: cedula + ".jpg" });
        this.getTemasdeSalud(1);
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  handleChange(event) {
    this.setState({ tituloTemaSalud: event.target.value });
  }

  sendData() {
    const data = {
      titulo: this.state.tituloTemaSalud,
      descripcion: this.state.descripcionTemaSalud,
    };

    fetch(this.state.serverUrl + "/v1/createtemasalud", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.status === 201) {
        this.getIdTemaSaludBeforeUploadingImage(this.state.tituloTemaSalud);
        document.getElementById("formulario").reset();
      }
    });
  }

  componentWillMount() {
    this.state.serverUrl = sessionStorage.getItem("serverUrl");
    let tokenStorage = sessionStorage.getItem("token");
    this.setState({
      token: tokenStorage,
      serverUrl: sessionStorage.getItem("serverUrl"),
    });
    this.getTemasdeSalud(1);
  }

  getTemasdeSalud(valueId) {
    fetch(this.state.serverUrl + "/v1/gettemassaludpaginados/" + valueId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        this.setState({
          arrayTemasSalud: data.infoData,
          totalPages: data.totalPages,
          statusLoad: false,
        });
      });
  }

  /*getTemasdeSalud() {
    fetch(this.state.serverUrl + "/v1/getalltemasdesalud", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        this.setState({ arrayTemasSalud: data, statusLoad: false });
      });
  }*/

  editarFamilia() {
    console.log("familia selected..");
  }
  handleClicks(s, id) {
    console.log("dada" + s);
  }

  eliminarTemasdeSalud(s, id) {
    fetch(this.state.serverUrl + "/v1/deletetemadesalud/" + s, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      body: JSON.stringify("data"),
    }).then((response) => {
      this.getTemasdeSalud(1);
    });
  }

  verTratatmientosDetalle(s, id) {}

  getclickedPagination(event, value) {
    this.getTemasdeSalud(value);
  }

  render() {
    return (
      <div>
        <Formulario
          onsubmit={this.submitForm}
          tituloTemaSalud={this.tituloTemaSalud}
          onChange={this.onChange}
          onFileChange={this.onFileChange}
          Pagination={Pagination}
          onChangeP={this.getclickedPagination}
          totalPages={this.state.totalPages}
        ></Formulario>
        <br /> <br />
        <center>
          {this.state.statusLoad ? <CircularProgress disableShrink /> : ""}
        </center>
        <br /> <br />
        <div></div>
        {this.state.arrayTemasSalud.map((item) => {
          return (
            <div key={item.id}>
              <MediaCard
                titulo={item.titulo}
                descripcion={item.descripcion}
                urlImage={item.imageUrl}
                totaltratamientos={item.vecesVisto}
                editar={this.handleClicks.bind(this, item.id)}
                eliminar={this.eliminarTemasdeSalud.bind(this, item.id)}
              ></MediaCard>
              <br /> <br />
              <Divider />
              <br /> <br />
            </div>
          );
        })}
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <center>
            <Pagination
              count={parseInt(this.state.totalPages)}
              color="primary"
              onChange={this.getclickedPagination}
            />
          </center>
        </Grid>
      </div>
    );
  }
}

export default TemasDeSalud;
